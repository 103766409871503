import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import List from "../custom-widgets/list";
import TwoColumnSectionWithImage from "../custom-widgets/two-column-section-with-image";

import { useLanguageContext } from "../../contexts/language-context";

const StartSavingsSection = ({ isGray }) => {
  const isSpanish = useLanguageContext();

  const startSavingsEsListData = {
    accountName: "start-savings-list",
    items: [
      {
        id: 1,
        text: "Solo $25 de depósito inicial"
      },
      {
        id: 2,
        text: "Sin requisitos de saldo mínimo"
      },
      {
        id: 3,
        text: "Sin cuotas mensuales de mantenimiento"
      },
      {
        id: 4,
        text: `<a href="/es/banca-personal/tasas-de-interes" id="tasas-de-interes-page-link">Tasa mixta variable</a> (la tasa de interés es diferente dependiendo de la cantidad de dinero que tengas en tu cuenta)`
      }
    ]
  };

  const SpanishContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold text-success">Start Savings</h2>
        <p>
          Con una tasa de interés más alta que una cuenta de ahorros tradicional, Start Savings está diseñada para
          ayudarte a ahorrar y ganar más dinero al hacerlo. Para abrirla, solo debes tener cualquier cuenta corriente de
          WaFd (incluida Nuevo Comienzo) y empieza a pagarte a ti mismo en el futuro.
        </p>
        <List {...startSavingsEsListData} />
        <Link to="/open-an-account" id="open-an-account-page-link" className="btn btn-primary w-100 w-sm-auto">
          Abre una cuenta Start Savings
        </Link>
      </>
    );
  };

  const startSavingsListData = {
    accountName: "start-savings-list",
    items: [
      {
        id: 1,
        text: "Only $25 initial deposit"
      },
      {
        id: 2,
        text: "No minimum balance requirements"
      },
      {
        id: 3,
        text: "No monthly service charge"
      },
      {
        id: 4,
        text: `<a href="/personal-banking/interest-rates" id="interest-rates-page-link">Variable blended rate</a> (interest rate is different depending on how much money you have in the account)`
      }
    ]
  };

  const EnglishContent = () => {
    return (
      <>
        <h2 className="font-weight-semi-bold text-success">Start Savings</h2>
        <p className="mb-3">
          With a higher interest rate than a traditional savings account, Start Savings is designed to help you save and
          earn more for doing it. To open, just have any WaFd Checking account (including Fresh Start) and start paying
          your future self.
        </p>
        <List {...startSavingsListData} />
        <Link to="/open-an-account" id="open-an-account-page-link" className="btn btn-primary w-100 w-sm-auto">
          Open a Start Savings Account
        </Link>
      </>
    );
  };

  return (
    <TwoColumnSectionWithImage id="start-savings-section" contentOnLeft={false} isGray={isGray}>
      {{
        image: (
          <StaticImage
            quality="100"
            placeholder="blurred"
            className="border-radius-12"
            src="../../images/thumbnail-start-saving-080124.jpg"
            alt={
              isSpanish
                ? "Hombre joven relajándose mientras trabaja en su portátil en casa."
                : "Young man relaxing while working on his laptop at home."
            }
          />
        ),
        content: isSpanish ? <SpanishContent /> : <EnglishContent />
      }}
    </TwoColumnSectionWithImage>
  );
};
export default StartSavingsSection;
