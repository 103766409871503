import React from "react";
import { Link } from "gatsby";
import ServiceStatesLinks from "./service-states-links";
import { useLanguageContext } from "../../contexts/language-context";

const ServiceStatesOpenAccountSection = (props) => {
  const isSpanish = useLanguageContext();
  const labels = {
    en: {
      title: "Open Your Account Online or at a Local Branch",
      ctaText: "Open an Account",
      ctaUrl: "/open-an-account"
    },
    es: {
      title: "Abra su cuenta en línea o en una sucursal local",
      ctaText: "Abre una Cuenta",
      ctaUrl: "/open-an-account"
    }
  };

  const currentLabels = isSpanish ? labels["es"] : labels["en"];
  return (
    <section id="service-states-open-account-section" className={props.containerClass}>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img alt="" src="/images/icons/service-states-icon.svg" className="w-100" />
          </div>
          <div className="col-md-9">
            <h2 className="font-weight-semi-bold">{currentLabels.title}</h2>
            <div className="mb-3">
              <ServiceStatesLinks />
            </div>
            {props.hasOpenAnAccountBtn && (
              <>
                <Link
                  to={currentLabels.ctaUrl}
                  className="btn btn-primary d-none d-md-inline-block"
                  id="service-states-cta-mb"
                >
                  {currentLabels.ctaText}
                </Link>
                <Link
                  to={currentLabels.ctaUrl}
                  className="btn btn-primary d-inline-block d-md-none w-100"
                  id="service-states-cta-dk"
                >
                  {currentLabels.ctaText}
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceStatesOpenAccountSection;

ServiceStatesOpenAccountSection.defaultProps = {
  containerClass: "bg-light",
  hasOpenAnAccountBtn: false
};
